/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by P   aul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {

                $('document').ready(function () {

                    $("[data-fancybox]").fancybox({
                        // Options will go here
                    });

                    //Search Button
                    $('#search-button a').click(function (e) {
                        e.preventDefault();
                        $('.header-search-form').toggleClass('closed');
                        $("i", this).toggleClass('fas fa-search').toggleClass('far fa-times-circle');
                    });

                    //Mobile Menu toggle
                    $('.mobile-menu').click(function () {
                        $('.nav-primary,.mobile-overlay, body').toggleClass('is-active');
                    });

                    //Mobile Menu Overlay (When Menu is collapsed)
                    $('.mobile-overlay').click(function () {
                        $('.nav-primary,.mobile-overlay, body').removeClass('is-active');
                    });

                    //Disable Hover clicks on Tablet/Mobile for Secondary+ Nav Items
                    /*$('.menu-item-has-children a').on('click', function(e){
                        if($(window).width() < 992) {
                            e.preventDefault();
                        }
                    });*/


                    //Add Focus Class Events for Secondary+ Nav ITems
                    $(function () {
                        $('.menu-item-has-children a').focus(function () {
                            $(this).siblings('.sub-menu').addClass('focused');
                            $(this).addClass('focused');

                        }).blur(function () {
                            $(this).siblings('.sub-menu').removeClass('focused');
                            $(this).removeClass('focused');

                        });

                        $('.sub-menu a').focus(function () {
                            $(this).parents('.sub-menu').addClass('focused');
                            $(this).addClass('focused');
                        }).blur(function () {
                            $(this).parents('.sub-menu').removeClass('focused');
                            $(this).removeClass('focused');
                        });
                    });

                    //Mobile/Tablet onClick Open for Secondary+ Items
                    $('nav.nav-primary .menu-item-has-children').each(function () {
                        var mobile_append =
                            "<span class=\"mobile-expand\"><i class=\"fas fa-plus\"></i></span>";
                        $(this).append(mobile_append);
                    });

                    $('.mobile-expand').on('click', function () {
                        $(this).siblings('.sub-menu').toggleClass('is-expanded');
                        $(this).toggleClass('is-expanded');
                    });


                    $('.banners.owl-carousel:not(.single-item)').owlCarousel({
                        loop: true,
                        items: 1,
                        autoplay: true,
                        autoplayTimeout: 10000
                    });

                    //Disable Slideshow if only a Single Item is in the Carousel
                    $('.banners.owl-carousel.single-item').owlCarousel({
                        loop: false,
                        items: 1,
                        autoplay: false,
                        mouseDrag: false,
                        touchDrag: false,
                        pullDrag: false,
                        freeDrag: false
                    });
                });

            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired

            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        },

        'search': {
            init: function () {
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
